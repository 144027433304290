
import { S3_BUCKET, S3_REGION } from '@/api';
import { WlcmHomeApi } from '@/api/WlcmHomeApi';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { WlcmHomeCampaign, WlcmHomeCampaignImage, Styles } from 'ignite360-core';
import { Component, Vue } from 'vue-property-decorator';
import LightBox from 'vue-image-lightbox'
@Component({
  name: 'public-wlcm-home-campaign',
  components: {LightBox},
})
export default class PublicWlcmHomeCampaign extends Vue {
  isLoading = false;

  campaign: WlcmHomeCampaign | null = null;

  showLightBox = false;

  get sortedImages() {
    return this.campaign?.images.slice().sort((a, b) => a.order - b.order) ?? [];
  }

  get heroImage() {
    return this.campaign?.images.filter(image => image.heroImage)[0] || undefined;
  }

  get heroImageIndex() {
    return this.sortedImages.findIndex(image => image.heroImage);
  }

  get media() {
    return this.sortedImages.map(image => ({
      thumb: this.imageUrl(image),
      src: this.imageUrl(image),
      srcset: this.imageUrl(image),
    }));
  }

  handleOpenLightBox(index: number) {
    if (this.$refs.lightbox) {
    (this.$refs.lightbox as any).showImage(index)
    }
  }

  getStyle(key: keyof Styles) {
    return this.campaign?.styles[key];
  }

  get campaignId(): string {
    return this.$route.params.id || this.$route.params.slug || '';
  }

  async created() {
    this.isLoading = true;
    try {
      // Create promises for both the API call and the delay
      const [campaignData] = await Promise.all([
        WlcmHomeApi.getPublicWlcmHomeCampaign(this.campaignId),
        new Promise(resolve => setTimeout(resolve, 500)) // 500ms delay
      ]);
      this.campaign = campaignData;
    } catch (e) {
      this.$notify.error({
        title: 'Loading project failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isLoading = false;
    }
  }

  getCampaignTitleSplit() {
    return this.campaign?.title.split(" ");
  }

  getCampaignDescriptionSplit() {
    return this.campaign?.description.split("\n");
  }

  imageUrl(image: WlcmHomeCampaignImage) {
    return `https://${S3_BUCKET}.s3.${S3_REGION}.amazonaws.com/bots/${this.campaign!.bot.id}/wlcm-home/campaigns/${this.campaign!.id}/images/${image.id}.${image.extension}`;
  }

  agentImageUrl(imageId: string) {
    return `https://${S3_BUCKET}.s3.${S3_REGION}.amazonaws.com/bots/${this.campaign!.bot.id}/wlcm-home/campaigns/${this.campaign!.id}/agent/${imageId}`;
  }

  get nonHeroImages() {
    return this.sortedImages.filter(image => !image.heroImage);
  }
}
