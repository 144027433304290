import { Api, BASE_URL, BOTS_BASE_URL } from '@/api/index';
import { WlcmHomeCampaignSettings } from '@/types';
import axios, { AxiosRequestConfig } from 'axios';
import { Agent, CreateAgent, CreateWlcmHomeCampaign, WlcmHomeCampaign } from 'ignite360-core';

export class WlcmHomeApi {
  static async loadBotWlcmHomeCampaigns(botId: string): Promise<WlcmHomeCampaign[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns`,
    });
    const result = await axios.request<WlcmHomeCampaign[]>(config);
    return result.data;
  }

  // create a new campaign
  static async createBotWlcmHomeCampaign(
    botId: string,
    campaign: CreateWlcmHomeCampaign,
  ): Promise<WlcmHomeCampaign> {
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns`,
      data: campaign,
    });
    const result = await axios.request<WlcmHomeCampaign>(config);
    return result.data;
  }

  static async getPublicWlcmHomeCampaign(campaignId: string): Promise<WlcmHomeCampaign> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BASE_URL}/public/wlcm-home/campaigns/${campaignId}`,
    });
    const result = await axios.request<WlcmHomeCampaign>(config);
    return result.data;
  }

  static async getBotWlcmHomeCampaign(
    botId: string,
    campaignId: string,
  ): Promise<WlcmHomeCampaign> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}`,
    });
    const result = await axios.request<WlcmHomeCampaign>(config);
    return result.data;
  }

  static async uploadImages(botId: string, campaignId: string, formData: FormData) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}/images`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async deleteBotWlcmHomeCampaign(botId: string, campaignId: string) {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}`,
    });
    return axios.request(config);
  }

  static async updateBotWlcmHomeCampaign(
    botId: string,
    campaignId: string,
    campaign: WlcmHomeCampaignSettings,
  ) {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}`,
      data: campaign,
    });
    return axios.request(config);
  }

  static async deleteBotWlcmHomeCampaignImage(botId: string, campaignId: string, imageId: string) {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}/images/${imageId}`,
    });
    return axios.request(config);
  }

  static async updateBotWlcmHomeCampaignImagesOrder(
    botId: string,
    campaignId: string,
    updatedOrder: { id: string; order: number }[],
  ) {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}/images/order`,
      data: { updatedOrder },
    });
    return axios.request(config);
  }

  // Add this method to the WlcmHomeApi class

  static async updateImageHeroStatus(
    botId: string,
    campaignId: string,
    imageId: string,
    heroImage: boolean,
  ) {
    const config = Api.finalizeConfig({
      method: 'PUT',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}/images/${imageId}/hero`,
      data: { heroImage },
    });
    return axios.request(config);
  }

  static async uploadAgentImage(botId: string, campaignId: string, formData: FormData) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}/agent-image`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.request(Api.finalizeConfig(config));
  }

  static async deleteAgentImage(botId: string, campaignId: string) {
    const config = Api.finalizeConfig({
      method: 'DELETE',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}/agent-image`,
    });
    return axios.request(config);
  }

  // // create a new campaign
  // static async createBotAgent(botId: string, agent: CreateAgent): Promise<Agent> {
  //   const config = Api.finalizeConfig({
  //     method: 'POST',
  //     url: `${BOTS_BASE_URL}/${botId}/wlcm-home/agents`,
  //     data: agent,
  //   });
  //   const result = await axios.request<Agent>(config);
  //   return result.data;
  // }

  static createBotAgent(botId: string, campaignId: string, formData: FormData) {
    
    
    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/campaigns/${campaignId}/agent`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return axios.request(config);
  }

  static async getBotAgents(botId: string) {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/wlcm-home/agents`,
    });
    return axios.request<Agent[]>(config);
  }
}
